import * as React from "react";

import { Box, Heading, SimpleGrid, Text } from "@chakra-ui/react";

import { MotionBox } from "../MotionBox";
import { StatCard } from "./StatCard";

// change this to a function component

export const Roadmap = () => {
  return (
    <Box as="section" pb="20">
      <Box bg="gold" pt="20" pb="20">
        <Box
          maxW={{
            base: "xl",
            md: "7xl",
          }}
          mx="auto"
          px={{
            base: "6",
            md: "8",
          }}
        >
          <Box mb="16" color="white" maxW="xl">
            <Heading
              size="2xl"
              letterSpacing="tight"
              fontWeight="extrabold"
              lineHeight="normal"
              color="#080E3F"
            >
              MemeCoinGen Project Roadmap
            </Heading>
            <Text fontSize="lg" mt="4" fontWeight="medium" color="#080E3F">
              We aim to be the hub for all memecoin creators! Helping people
              easily create the next multi-million dollar memecoin and assisting
              creators in everything from launch to marketing.
            </Text>
          </Box>
        </Box>
      </Box>
      <Box mt="-20">
        <Box
          maxW={{
            base: "xl",
            md: "7xl",
          }}
          mx="auto"
          px={{
            base: "6",
            md: "8",
          }}
        >
          <SimpleGrid
            columns={{
              base: 1,
              md: 2,
              lg: 4,
            }}
            spacing="6"
          >
            <MotionBox
              whileHover={{ scale: 1.1 }}
              // animate={{ x: 100, y: 100, opacity: 1 }}
              transition={{
                delay: 1,
                x: { type: "spring", stiffness: 100 },
                default: { duration: 0.3 },
              }}
              bg="##b5b5b5"
            >
              <StatCard isStrike title="Q2 2021" value="🚢">
                <Text color="#080E3F">
                  <strike>Launch our ERC20 Coin Generator. </strike>✅
                </Text>{" "}
              </StatCard>
            </MotionBox>

            <MotionBox
              bg="##b5b5b5"
              whileHover={{ scale: 1.1 }}
              // animate={{ x: 100, y: 100, opacity: 1 }}
              transition={{
                delay: 1,
                x: { type: "spring", stiffness: 100 },
                default: { duration: 0.3 },
              }}
            >
              <StatCard isStrike title="Q2 2021" value="🔥">
                <Text color="#080E3F">
                  <strike>Allow SafeMoon style customisable Tokenomics!</strike>
                  ✅
                </Text>{" "}
              </StatCard>
            </MotionBox>

            <MotionBox
              whileHover={{ scale: 1.1 }}
              // animate={{ x: 100, y: 100, opacity: 1 }}
              transition={{
                delay: 1,
                x: { type: "spring", stiffness: 100 },
                default: { duration: 0.3 },
              }}
            >
              <StatCard title="Q4 2023" value="🔱">
                Coin Dashboard with analytics to track your created coin.
              </StatCard>
            </MotionBox>

            <MotionBox
              whileHover={{ scale: 1.1 }}
              // animate={{ x: 100, y: 100, opacity: 1 }}
              transition={{
                delay: 1,
                x: { type: "spring", stiffness: 100 },
                default: { duration: 0.3 },
              }}
            >
              <StatCard title="Q1 2024" value="🚀">
                Airdrop qualification for users 👀
              </StatCard>
            </MotionBox>
          </SimpleGrid>
        </Box>
      </Box>
    </Box>
  );
};
