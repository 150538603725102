import * as React from "react";

import {
  Box,
  Button,
  Stack,
  Text,
  useColorModeValue as mode,
} from "@chakra-ui/react";

export const Feature = (props) => {
  const { title, children, icon } = props;
  return (
    <Stack
      spacing={{ base: "3", md: "6" }}
      direction={{ base: "column", md: "row" }}
    >
      <Box
        fontSize="6xl"
        display="flex"
        justifyContent={["center", "center", "left", "left"]}
      >
        {icon}
      </Box>
      <Stack spacing="1">
        <Text
          fontWeight="bold"
          fontSize="lg"
          textAlign={["center", "center", "left", "left"]}
          color="gold"
        >
          {title}
        </Text>
        <Box color={"wheat"} textAlign={["center", "center", "left", "left"]}>
          {children}
        </Box>
      </Stack>
    </Stack>
  );
};
