import { Box, HStack, Heading, Stack, Text, chakra } from "@chakra-ui/react";

import { Feature } from "./feature";
import { InvitationForm } from "./invitationForm";
import React from "react";

export const Presale = () => {
  return (
    <Box
      as="section"
      py={{
        lg: "12",
      }}
    >
      <Box
        bg="#041578"
        rounded={{
          lg: "2xl",
        }}
        maxW="5xl"
        mx="auto"
        px={{
          base: "4",
          sm: "6",
          lg: "8",
        }}
        py={{
          base: "12",
          sm: "16",
        }}
      >
        <Box maxW="xl" mx="auto" color="white" textAlign="center">
          <Text mb="4" fontSize="lg" color="gold" fontWeight="semibold">
            Stay Informed!{" "}
          </Text>
          {/* <CountdownTime /> */}
          <Heading
            as="h2"
            mb="8"
            size="xl"
            fontWeight="extrabold"
            letterSpacing="tight"
          >
            Sign up to keep up to date with our latest developments!
          </Heading>

          <InvitationForm />

          <Stack
            spacing={{
              base: "3",
              md: "6",
            }}
            direction={{
              base: "column",
              md: "row",
            }}
            mt="4"
            justify="center"
            align="center"
          >
            <Feature>Early Adopter Special!</Feature>
            <Feature>We Won't Spam You</Feature>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};
