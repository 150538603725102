import * as React from "react";

import {
  AspectRatio,
  Box,
  Button,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  useColorModeValue as mode,
  useDisclosure,
} from "@chakra-ui/react";

import { HiPlay } from "react-icons/hi";
import { MotionBtn } from "./MotionBox";
import NextLink from "next/link";
import styles from "./style.module.scss";
import { usePlausible } from "next-plausible";

export function Hero() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const plausible = usePlausible();

  const click_create_token = () => {
    plausible("CTA", {
      props: {
        section: "Main",
        btnID: "Create Your Coin",
      },
    });
  };

  return (
    <Box as="section" bg="#080e40" pt="16" pb="24">
      <Box
        maxW={{
          base: "xl",
          md: "7xl",
        }}
        mx="auto"
        px={{
          base: "6",
          md: "8",
        }}
      >
        <Stack
          direction={{
            base: "column",
            lg: "row",
          }}
          spacing={{
            base: "3rem",
            lg: "2rem",
          }}
          mt="8"
          align={{
            lg: "center",
          }}
          justify="space-between"
        >
          <Box
            flex="1"
            maxW={{
              lg: "520px",
            }}
          >
            <Text
              size="xs"
              textTransform="uppercase"
              fontWeight="semibold"
              color="gold"
              letterSpacing="wide"
            >
              Start your moon mission today!
            </Text>
            <Heading
              as="h1"
              size="3xl"
              // color={mode("blue.600", "blue.300")}
              color="antiquewhite"
              mt="4"
              fontWeight="extrabold"
              letterSpacing="tight"
            >
              Create your own{" "}
              <div className={styles["content"]}>
                <div className={styles["content__container"]}>
                  <ul className={styles["content__container__list"]}>
                    <li className={styles["content__container__list__item"]}>
                      MemeCoin!
                    </li>
                    <li className={styles["content__container__list__item"]}>
                      AltCoin!
                    </li>

                    <li className={styles["content__container__list__item"]}>
                      Utility Coin!
                    </li>
                    <li className={styles["content__container__list__item"]}>
                      Charity Coin!
                    </li>
                  </ul>
                </div>
              </div>
              {/* memecoin! */}
            </Heading>
            <Text
              color={mode("gray.600", "gray.400")}
              // mt="4"
              fontSize="lg"
              fontWeight="medium"
              color="antiquewhite"
            >
              Create your own cryptocurrency token without having to know any
              code
            </Text>
            <Stack
              direction={{
                base: "column",
                md: "row",
              }}
              spacing="4"
              mt="8"
            >
              <NextLink href="/create-token">
                <MotionBtn
                  whileHover={{ scale: 1.1, cursor: "pointer" }}
                  whileTap={{ scale: 0.9 }}
                  as="a"
                  size="lg"
                  // _hover={{ bg: "white" }}
                  minW="210px"
                  bg="gold"
                  height="14"
                  px="8"
                  onClick={click_create_token}
                >
                  <Image src="/pancakeswap-cake-logo.svg" w="32px" />
                  <Text ml="2" color="black">
                    {" "}
                    Create Your Coin!
                  </Text>
                </MotionBtn>
              </NextLink>

              <Button
                size="lg"
                bg="white"
                color="gray.900"
                _hover={{
                  bg: "gray.50",
                }}
                height="14"
                px="8"
                shadow="base"
                leftIcon={<Box as={HiPlay} fontSize="2xl" />}
                onClick={(e) => {
                  plausible("CTA", {
                    props: {
                      section: "Main",
                      btnID: "How To Use",
                    },
                  });
                  onOpen();
                }}
              >
                How To Use
              </Button>
              <Modal bg="black" isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                  <ModalCloseButton color="white" />
                  <ModalBody bg="black">
                    <AspectRatio maxW="480" height="800" ratio={1}>
                      <iframe
                        src="https://streamable.com/e/pu9s1q?autoplay=1"
                        frameborder="0"
                        width="100%"
                        height="100%"
                        allowfullscreen
                        allow="autoplay"
                      ></iframe>
                    </AspectRatio>
                  </ModalBody>
                </ModalContent>
              </Modal>
            </Stack>
            <Box>
              <Box display="flex" flexDir="row">
                <Text mt="8" color="white" mr="2">
                  Available on
                </Text>
                <Image
                  display="inline"
                  alignSelf="flex-end"
                  src="/ethereum-eth.svg"
                  w="24px"
                  h="24px"
                />{" "}
                <Text
                  display="flex"
                  ml="2"
                  mr="4"
                  fontWeight="bold"
                  alignItems="flex-end"
                  color="white"
                >
                  ETH
                </Text>
                <Image
                  display="inline"
                  alignSelf="flex-end"
                  src="/binance-coin-bnb-logo.png"
                  w="24px"
                  h="24px"
                />{" "}
                <Text
                  display="flex"
                  ml="2"
                  fontWeight="bold"
                  alignItems="flex-end"
                  color="white"
                  mr="4"
                >
                  BSC
                </Text>
                <Image
                  w="24px"
                  h="24px"
                  mr="4"
                  display="inline"
                  alignSelf="flex-end"
                  alignItems="flex-end"
                  color="white"
                  src="/5426.png"
                  style={{
                    // grayscale filter
                    filter: "grayscale(100%)",
                  }}
                />
                <Text
                  display="flex"
                  fontWeight="bold"
                  alignItems="flex-end"
                  color="white"
                  mr="4"
                >
                  SOL (coming soon)
                </Text>
              </Box>
            </Box>
          </Box>
          <Box
            pos="relative"
            w={{
              base: "full",
              lg: "560px",
            }}
            h={{
              base: "auto",
              lg: "560px",
            }}
          >
            <Image
              w="full"
              pos="relative"
              zIndex="1"
              h={{
                lg: "100%",
              }}
              objectFit="cover"
              src="/animated_header_1.svg"
              alt="Screening talent"
            />
          </Box>
        </Stack>
      </Box>
    </Box>
  );
}
