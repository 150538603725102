import * as React from "react";

import {
  Box,
  Button,
  FormLabel,
  Input,
  LightMode,
  Stack,
  chakra,
} from "@chakra-ui/react";
import { ValidationError, useForm } from "@formspree/react";

import { ethers } from "ethers";

export const InvitationForm = () => {
  const [state, handleSubmit] = useForm("mnqljega");

  if (state.succeeded) {
    return <p>Thanks for subscribing!</p>;
  }

  return (
    <chakra.form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(e);
      }}
    >
      <LightMode>
        <Stack
          direction={{
            base: "column",
            md: "row",
          }}
          align={{
            md: "flex-end",
          }}
        >
          {/* <form onSubmit={handleSubmit}> */}
          <Box flex="1" display="flex" flexDir="column">
            <FormLabel htmlFor="email" srOnly>
              Your email address:
            </FormLabel>
            <Box text-align="center" display="flex">
              <Input
                id="email"
                name="email"
                size="lg"
                fontSize="md"
                bg="white"
                _placeholder={{
                  color: "gray.400",
                }}
                color="gray.900"
                placeholder="@"
                focusBorderColor="blue.200"
              />
              <Button
                ml={4}
                type="submit"
                disabled={state.submitting}
                size="lg"
                colorScheme="yellow"
                fontSize="md"
                px="10"
                // onClick={(e) => e.preventDefault()}
              >
                Stay Informed!
              </Button>
            </Box>
          </Box>
          {/* </form> */}
          <ValidationError prefix="Email" field="email" errors={state.errors} />
        </Stack>
      </LightMode>
    </chakra.form>
  );
};
