import { Box, Heading, Text } from "@chakra-ui/react";

import { Features } from "./Features";
import { Hero } from "./Hero";
import { Presale } from "../Components/PreSale";
import React from "react";
import { Roadmap } from "./Roadmap";
import ContractEvents from "./ContractEvents";
import Marquee from "./Marquee";
export const FrontPage = () => {
  return (
    <>
      <Box mw="1980px" bg="#080e40">
        <Hero />
        <Box>
          <Heading mb={12} as="h3" color="white" textAlign="center">
            We've helped over{" "}
            <Text display="inline" color="gold">
              10000+{" "}
            </Text>{" "}
            people create their own coins!
          </Heading>
        </Box>

        {/* <Marquee>
          <ContractEvents />
        </Marquee> */}

        <Features />
        {/* <Tokenomics /> */}
        <Roadmap />
        <Presale />
      </Box>
    </>
  );
};
