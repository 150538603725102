// import FrontPage from "../Components/Frontpage";
import "@fontsource/rubik/variable.css";

import { Box, Button } from "@chakra-ui/react";

import { FaTelegramPlane } from "react-icons/fa";
import { Footer } from "../Components/Footer";
import { FrontPage } from "../Components/Frontpage";
import Head from "next/head";
import Menu from "../Components/Menu";
import React from "react";

export default function Home({ photoURL }) {
  return (
    <Box>
      {/* {plausible("index test")} */}
      <style global>{`
        html,
        body {
          padding: 0;
          margin: 0;
          font-family: Rubik;
        }

        * {
          box-sizing: border-box;
        }
      `}</style>

      <Head>
        <title>Make Your Own Memecoins - MemeCoinGen</title>
        <meta name="description" content="Make your own cryptocurrency!" />
        <link rel="icon" href="/favicon.ico" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
          rel="stylesheet"
        />
        <meta
          property="og:image"
          content="https://i.ibb.co/Xts2DNV/memecoingen-twittercard.png"
        />
        <meta
          property="og:image:secure_url"
          content="https://i.ibb.co/Xts2DNV/memecoingen-twittercard.png"
        />
        <meta
          name="twitter:image"
          content="https://i.ibb.co/Xts2DNV/memecoingen-twittercard.png"
        />
      </Head>
      <Box display="flex" bg="#041578" p={1.5} justifyContent="center">
        <Box color="white" fontWeight="semibold" textAlign="center">
          {" "}
          You can join us on Telegram!{" "}
          <Button
            ml={4}
            size="xs"
            color="#041578"
            as="a"
            href="https://t.me/joinchat/3YFIbEo4mNk2NDM8"
            target="_blank"
            // onClick={event_click_telegram_btn}
          >
            <Box mr={2}>
              <FaTelegramPlane fontSize="16px"></FaTelegramPlane>
            </Box>
            Click Here!{" "}
          </Button>
        </Box>
      </Box>
      <Box
        bg="#080e40"
        as="header"
        display="flex"
        justifyContent="center"
        position="sticky"
      >
        <Menu isDark />
      </Box>
      <main>
        <FrontPage />
      </main>

      <Box as="footer" background="#080e40" color="gold">
        <Footer />
      </Box>
    </Box>
  );
}
