import { Box, chakra } from "@chakra-ui/react";
import { motion } from "framer-motion";
import styled from "@emotion/styled";
import React from "react";
// 2. Defining Variants
const marqueeVariants = {
  animate: {
    x: [2000, -2440],
    transition: {
      x: {
        repeat: Infinity,
        repeatType: "loop",
        duration: 30,
        ease: "linear",
      },
    },
  },
};

// Define your components using styled-components and Chakra UI
const MarqueeContainer = styled.div`
  position: relative;
  width: 100vw;
  max-width: 100%;
  height: 206px;
  overflow-x: hidden;
`;

const Track = styled(chakra(motion.div))`
  position: absolute;
  white-space: nowrap;
`;

const MarqueeText = styled(chakra.div)`
  margin: 20px 0;
  font-size: 24px;
  /* font-family: Antonio; */
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #f4955c;
  text-transform: uppercase;
`;

const Marquee = ({ children }) => {
  return (
    <MarqueeContainer>
      <Box textAlign={"center"} color="white" fontSize={18}>
        <h2>Coins launched in the past 48hrs:</h2>
      </Box>
      <Track variants={marqueeVariants} animate="animate">
        <MarqueeText>{children}</MarqueeText>
      </Track>
    </MarqueeContainer>
  );
};

export default Marquee;
