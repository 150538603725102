import * as React from "react";

import {
  FcDoughnutChart,
  FcMultipleDevices,
  FcPrivacy,
  FcTimeline,
} from "react-icons/fc";

import { Box } from "@chakra-ui/react";
import { Feature } from "./feature";
import { MotionGrid } from "../MotionBox";

export const Features = () => {
  const [lastYPos, setLastYPos] = React.useState(0);
  const [shouldShowActions, setShouldShowActions] = React.useState(false);

  React.useEffect(() => {
    function handleScroll() {
      const yPos = window.scrollY;
      const isScrollingUp = yPos > lastYPos;

      isScrollingUp && setShouldShowActions(isScrollingUp);
      setLastYPos(yPos);
    }

    window.addEventListener("scroll", handleScroll, false);

    return () => {
      window.removeEventListener("scroll", handleScroll, false);
    };
  }, [lastYPos]);

  return (
    <Box
      as="section"
      maxW="5xl"
      mx="auto"
      py="12"
      px={{
        base: "6",
        md: "8",
      }}
    >
      <MotionGrid
        initial={{ opacity: 0 }}
        animate={{ opacity: shouldShowActions ? 1 : 0 }}
        transition={{ opacity: { duration: 2 }, delayChildren: 1 }}
        columns={{
          base: 1,
          md: 2,
        }}
        spacingX="10"
        spacingY={{
          base: "8",
          md: "14",
        }}
      >
        <Feature
          title="Start your Memecoin Campaign Today!"
          icon={<FcPrivacy />}
        >
          Join the ranks of $DOGE $SHIBA $CUMMIES & $SAFEMOON.  Your next coin
          could reach $1 billion market cap!
        </Feature>
        <Feature title="No Code Needed" icon={<FcTimeline />}>
          Generate your token at the click of a button! Just connect your
          wallet, pick your options and create your token!
        </Feature>
        <Feature title="Customizable Tokenomics" icon={<FcDoughnutChart />}>
          Creating coins on the memecoingen platform allows you for deep
          customisable memecoins creating a fun experience for your holders
        </Feature>
        <Feature
          title="Fully Compliant & Verified Contracts"
          icon={<FcMultipleDevices />}
        >
          All contracts are fully compliant to ERC20 / BSC20 contract standards
          and are fully usable on Ethereum BSC & Solana (coming soon!).
        </Feature>
      </MotionGrid>
    </Box>
  );
};
