import * as React from "react";

import { Box, Text, motion, useColorModeValue } from "@chakra-ui/react";

export const StatCard = (props) => {
  const { title, value, children, isStrike } = props;
  return (
    <Box
      as="dl"
      bg={isStrike ? "gray" : useColorModeValue("white", "gray.700")}
      // _hover={{ background: "white" }}
      p="6"
      rounded="lg"
      shadow="md"
    >
      <Text
        as="dt"
        color={isStrike ? "#080E3F" : useColorModeValue("blue.500", "blue.300")}
        fontSize="sm"
        fontWeight="bold"
      >
        {isStrike ? <strike>{title}</strike> : title}
      </Text>
      <Text as="dd" fontSize="5xl" fontWeight="extrabold" lineHeight="1" my="4">
        {value}
      </Text>
      <Text as="dd" color={useColorModeValue("gray.600", "white")}>
        {children}
      </Text>
    </Box>
  );
};
